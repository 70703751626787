<template>
   <div class="card mb-0" ref="noticia">
      <div class="card-buttons-top align-items-center" v-if="dadosUsuario.usuarioPermissoes.includes('Editar blog')">
         <div class="row justify-content-center">
            <div class="col-sm-6 text-center mb-1 mb-sm-0">
               <a href="javascript:;" class="badge badge-default" @click="editar">
                  <i class="fal fa-pen font-11 me-1"></i> Editar
               </a>
            </div>
            <div class="col-sm-6 text-center">
               <a href="javascript:;" class="badge badge-default-outline" @click="remover">
                  <i class="far fa-trash font-11 me-1"></i> Remover
               </a>
            </div>
         </div>
      </div>
      <img :src="noticia.icone == null ? '' : noticia.icone" class="card-img-top" alt="noticia" @error="imageError">
      <div class="card-body">
         <h5 class="limitador-1">{{ noticia.titulo == null || noticia.titulo.trim().length == 0 ? 'Sem título' : noticia.titulo }}</h5>
         <span class="text-secondary">{{ noticia.data.dayOfMonth.toString().padStart(2, '0') +'/'+ noticia.data.monthValue.toString().padStart(2, '0') +'/'+ noticia.data.year }} {{ noticia.data.hour.toString().padStart(2, '0') +':'+ noticia.data.minute.toString().padStart(2, '0') }}</span>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Noticia',
   props: ['noticia', 'index'],
   computed: {
		...mapState({
			dadosUsuario: state => state.dadosUsuario,
			urlRest: state => state.urlRest
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      editar : function () {
         this.$emit('editar', this.noticia)
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Remover postagem?',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'configuracoes/removeNoticia',
                  params:{
                     id: this.noticia.id
                  }
               }).then(() => {
                  this.$refs.noticia.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Notícia removida!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogarAdmin')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      }
   }
}

</script>

<style scoped>

.card-buttons-top {
   opacity: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   top: 0;
   bottom: 73px;
   left: 0;
   right: 0;
   background: color-mix(in srgb, #fff 75%, #0000);
   transition: opacity 0.3s;
}

.card:hover .card-buttons-top {
   opacity: 1;
}

h5 {
   font-size: 15px !important;
   margin-bottom: 5px !important;
}

span {
   font-size: 12px !important;
   display: block;
}

img {
	aspect-ratio: 3/2;
	object-fit: cover;
}

</style>